<template>
  <v-row v-if="giftFilters" class="flex-column flex-md-row my-5 my-md-0">
    <v-col cols="12" sm="auto" md="3" class="gift-filter">
      <v-select
        class="primary--text"
        dense
        clearable
        single-line
        outlined
        hide-details
        :items="giftFilters"
        prepend-inner-icon="$filter_light_sharp"
        label="FILTRI"
        v-model="warehouseClassToFilter"
        item-text="label"
        item-value="id"
        @change="handleFilterResults"
      ></v-select>
    </v-col>
  </v-row>
</template>
<style lang="scss">
.gift-filter {
  .v-input__prepend-inner {
    margin-right: 12px !important;
  }
  .v-input {
    fieldset {
      border-color: var(--v-primary-lighten1);
    }
  }
}
</style>

<script>
// import AddressService from "~/service/addressService";
export default {
  name: "GiftFilters",

  data() {
    return {
      warehouseClassToFilter: null,
      // Valido: oltre i 5 giorni dalla scadenza
      // In scadenza: meno di 5 giorni dalla scadenza
      // Scaduto
      // Utilizzato
      selectedGiftFilters: null
    };
  },
  computed: {
    /*warehouseFiltersLength() {
      return 12 / this.warehouseFilters.length;
    }*/
  },
  props: {
    giftFilters: {
      type: Array,
      required: true
    }
  },
  methods: {
    async handleFilterResults() {
      var _this = this;
      this.selectedGiftFilters = this.giftFilters.filter(
        value => value.id === _this.warehouseClassToFilter
      );
      global.EventBus.$emit("filterGifts", _this.selectedGiftFilters);
    }
  }
};
</script>
