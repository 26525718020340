<template>
  <v-card
    class="gift-card  default--text"
    :class="mode"
    :flat="mode == 'checkout'"
    v-if="
      autoAssigned ||
        (!autoAssigned && giftCertificate.userGiftCertificates.length > 0)
    "
  >
    <v-row>
      <v-col
        :cols="mode == 'full' ? 12 : 2"
        :sm="mode == 'full' ? 2 : 1"
        class="d-flex justify-center align-center h-100"
      >
        <v-img
          cover
          v-if="
            giftCertificate.categoryProposal &&
              giftCertificate.categoryProposal.img
          "
          :src="giftCertificate.categoryProposal.img"
          :alt="giftCertificate.categoryProposal.name"
          class="rounded-sm h-100 invite-friend-img"
        />
        <div
          v-else
          class="certificate-box is-card d-flex flex-column justify-space-around align-center"
          :class="cssClass"
          @click.prevent.stop="handleClick"
          outlined
        >
          <span class="icon-circle">
            <v-icon size="48" color="primary">{{ icon }}</v-icon>
          </span>
        </div>
      </v-col>

      <v-col
        :cols="mode == 'full' ? 12 : 10"
        :sm="mode == 'full' ? 10 : 11"
        class="d-flex flex-column body-column"
      >
        <div class="text-body-1 font-weight-bold">
          {{ name }}
        </div>

        <div
          class="expiration text-caption grey lighten-4"
          v-if="!hideEndDate && certificateExpiryDate"
        >
          <span> {{ $t("checkout.expires") }} </span>
          {{ $dayjs(giftCertificate.endDate).format("D/MM/YYYY") }}
        </div>
        <div class="description" v-html="description"></div>
        <div
          class="text-body-2 mt-2"
          v-if="mode == 'full' && listUsedCertificates.length > 0"
        >
          Utilizzato in questi ordini:
          <v-chip
            v-for="used in listUsedCertificates"
            :key="used.orderId"
            :to="{ name: 'Order', params: { orderId: used.orderId } }"
            class="mr-2"
          >
            <span class="text-caption">#{{ used.orderId }}</span>
          </v-chip>
        </div>

        <div
          class="text-body-2 mt-2"
          v-if="!isAssignable && listAvailableCertificates.length > 0"
        >
          <b>BUONI UTILIZZABILI</b><br />
          <div
            v-for="available in listAvailableCertificates"
            :key="available.userGiftCertificateId"
            class="d-flex align-center usable-gift-container mr-0 mr-md-4 row px-0 px-md-2 mx-2 py-6 py-md-4 mx-md-0"
          >
            <div class="color-column" :class="classStatus(available)"></div>
            <div class="d-flex flex-column">
              <div>
                <span>{{ available.name }}</span>
                <strong v-if="available.amount">
                  &nbsp;{{ $n(available.amount, "currency") }}
                </strong>
              </div>
              <div
                class="expiration text-caption lighten-4 d-block gift-status"
              >
                <span
                  class="font-weight-bold text-uppercase px-1"
                  :class="classStatus(available)"
                >
                  {{ decodeGiftStatus(available) }}</span
                >
              </div>
              <div
                v-if="!hideEndDate && !certificateExpiryDate"
                class="expiration text-caption lighten-4 d-block"
              >
                <span> {{ $t("checkout.expires") }} </span>
                {{ $dayjs(available.endDate).format("D/MM/YYYY") }}
              </div>
            </div>

            <v-spacer />

            <div
              class="d-flex flex-row share-code px-0 px-md-10 justify-center align-center"
              v-if="
                available.giftCode &&
                  available.giftCertificateStatus.giftCertificateStatusId != 3
              "
            >
              CODICE: <b class="ml-2">{{ available.giftCode }}</b>
              <div class="d-flex flex-wrap social-buttons ma-4">
                <span
                  v-for="(socialPlatform, index) in socialPlatforms"
                  :key="index"
                  @click="copyCode(available.giftCode)"
                  class="image-copy"
                >
                  <!-- <v-icon color="primary lighten-1">$share</v-icon> -->
                  <v-img
                    src="/img_layout/copy.png"
                    color="primary"
                    width="20px"
                  />
                </span>
              </div>
            </div>
            <v-btn
              v-show="!hideUseButton(available)"
              v-if="
                available.cartId != cart.cartId &&
                  available.giftCertificateStatus.giftCertificateStatusId != 3
              "
              small
              depressed
              color="primary"
              @click="handleAddUserGift(available.userGiftCertificateId)"
              class="px-6 py-4 mt-4 mt-md-0  d-flex gift-btn"
            >
              {{ $t("checkout.giftCodeUseNow") }}
            </v-btn>
            <v-btn
              v-else-if="
                available.giftCertificateStatus.giftCertificateStatusId != 3
              "
              v-show="hideUseButton(available)"
              small
              depressed
              color="primary"
              outlined
              @click="remove(available.userGiftCertificateId)"
              class="px-6 py-4 mt-4 mt-md-0  d-flex gift-btn"
            >
              {{ $t("checkout.giftCodeRemove") }}
            </v-btn>
          </div>
        </div>
        <div v-else class="text-body-2 mt-2">
          <div
            class="d-flex align-center usable-gift-container mr-0 mr-md-4 row px-0 px-md-2 mx-2 py-6 py-md-4 mx-md-0"
          >
            <div
              class="color-column"
              :class="[
                classStatus(giftCertificate),
                { 'auto-assigned': autoAssigned }
              ]"
            ></div>
            <div class="d-flex flex-column">
              <div v-if="!autoAssigned">
                <span>{{ giftCertificate.name }}</span>
                <strong v-if="giftCertificate.amount">
                  &nbsp;{{ $n(giftCertificate.amount, "currency") }}
                </strong>
              </div>
              <div
                class="expiration text-caption lighten-4 d-block gift-status"
              >
                <span
                  class="font-weight-bold text-uppercase px-1"
                  :class="classStatus(giftCertificate)"
                >
                  {{ decodeGiftStatus(giftCertificate) }}</span
                >
              </div>
              <div
                v-if="!hideEndDate && !certificateExpiryDate"
                class="expiration text-caption lighten-4 d-block"
              >
                <span> {{ $t("checkout.expires") }} </span>
                {{ $dayjs(giftCertificate.endDate).format("D/MM/YYYY") }}
              </div>
            </div>

            <v-spacer />

            <div
              class="d-flex flex-row share-code px-0 px-md-10 justify-center align-center"
              v-if="giftCertificate.giftCode"
            >
              CODICE: <b class="ml-2">{{ giftCertificate.giftCode }}</b>
              <div class="d-flex flex-wrap social-buttons ma-4">
                <span
                  v-for="(socialPlatform, index) in socialPlatforms"
                  :key="index"
                  @click="copyCode(giftCertificate.giftCode)"
                  class="image-copy"
                >
                  <v-img
                    src="/img_layout/copy.png"
                    color="primary"
                    width="20px"
                  />
                </span>
              </div>
            </div>

            <v-card-actions
              class="pa-0 space-between align-center"
              :class="{ 'w-100': $vuetify.breakpoint.smAndDown }"
            >
              <v-btn
                v-if="mode == 'full' && link"
                outlined
                :large="mode == 'full'"
                :small="mode == 'checkout'"
                depressed
                color="primary"
                :to="link"
              >
                {{ btnLabel }}
              </v-btn>
              <v-spacer />
              <v-btn
                v-if="isAssignable && userGiftCertificateId != null"
                :large="mode == 'full'"
                :small="mode == 'checkout'"
                depressed
                outlined
                color="primary"
                class="px-6 py-4 mt-4 mt-md-0  d-flex gift-btn"
                @click="remove(giftCertificate.userGiftCertificateId)"
                >{{ $t("checkout.giftCodeRemove") }}
              </v-btn>
              <v-btn
                v-else-if="isAssignable"
                :large="mode == 'full'"
                :small="mode == 'checkout'"
                depressed
                color="primary"
                @click="handleAdd(giftCertificate)"
                class="px-6 py-4 mt-4 mt-md-0  d-flex gift-btn"
                >{{ $t("checkout.giftCodeUseNow") }}</v-btn
              >
            </v-card-actions>
          </div>
        </div>

        <v-spacer />
      </v-col>
    </v-row>
  </v-card>
</template>
<style scoped lang="scss">
.gift-card {
  .icon-circle {
    border: 2px var(--v-primary-base) solid !important;
    border-radius: 50%;
    padding: 20px;
    .v-icon {
      font-size: 18px;
    }
  }
  border-bottom: 1px solid var(--v-grey-lighten4);
  border-radius: 0px;
  //padding-bottom: 4px;
  .body-column {
    padding-left: 8px;
  }

  &.full {
    border-radius: 4px;
    .body-column {
      padding: 20px !important;
    }
  }
  .v-image {
    @media #{map-get($display-breakpoints, 'xs-only')} {
      border-bottom-left-radius: 0 !important;
      border-bottom-right-radius: 0 !important;
    }
    @media #{map-get($display-breakpoints, 'sm-and-up')} {
      border-top-right-radius: 0 !important;
      border-bottom-right-radius: 0 !important;
    }
  }
  .expiration {
    width: fit-content;
    padding: 2px;
  }
  .description {
    ::v-deep ul {
      padding-left: 24px !important;
      li {
        list-style: disc !important;
      }
    }
  }
  .auto-assigned {
    padding: 2% 0 !important;
  }
  .usable-gift-container {
    .color-column {
      width: 5px;
      padding: 4% 0;
      margin-right: 5px;
      @media #{map-get($display-breakpoints, 'xs-only')} {
        padding: 10% 0;
      }
      &.valid {
        background-color: #cadd96;
      }
      &.expiring {
        background-color: #f3dd6b;
      }
      &.expired {
        background-color: #ff9797;
      }
      &.used {
        background-color: #c1c1c1;
      }
    }
    border-bottom: 1px solid #c1c1c1;
    margin-bottom: 4px;
  }
  .expiration {
    .valid {
      background-color: #cadd96;
      border: 1px solid;
      border-radius: 5px;
      border-color: #cadd96;
    }
    .expiring {
      background-color: #f3dd6b;
    }
    .expired {
      background-color: #ff9797;
    }
    .used {
      background-color: #c1c1c1;
    }
  }
  .share-code {
    .social-buttons {
      gap: 10px;
      .share-icon {
        cursor: pointer !important;
        img {
          width: 50px;
        }
        .icon-share:before {
          padding-left: 4px;
        }
      }
    }
    .image-copy {
      // background-image: url("/img_layout/copy.png");
      // background-size: 70%;
      color: $primary;
      cursor: pointer;
    }
  }
  .gift-btn {
    @media #{map-get($display-breakpoints, 'xs-only')} {
      width: 100%;
    }
  }
}
</style>
<script>
import giftCertificate from "@/components/gift/giftCertificate";
import { mapActions } from "vuex";

export default {
  name: "GiftCard",
  mixins: [giftCertificate],
  props: {
    mode: {
      type: String,
      default: "full"
    },
    autoAssigned: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    certificateExpiryDate() {
      const expirationType =
        this.giftCertificate &&
        this.giftCertificate.redemption == "autoassignment"
          ? true
          : false;
      return expirationType;
    }
  },
  methods: {
    ...mapActions({
      getCart: "cart/getCart"
    }),
    hideUseButton(gift) {
      let result = false;
      /*let endDate = this.$dayjs(userSubscription.endDate).format("YYYY-MM-DD");*/
      let today = this.$dayjs().format("YYYY-MM-DD");
      today = this.$dayjs(today);
      let startDate = this.$dayjs(gift.startDate);
      // endDate = "2024-06-10";
      let endDate = this.$dayjs(gift.endDate);
      // today = new Date(today);

      if (
        (gift.giftCertificateStatus &&
          gift.giftCertificateStatus.giftCertificateStatusId == 3) ||
        (startDate <= today && endDate < today)
      ) {
        result = true;
      }
      return result;
    },
    decodeGiftStatus(gift) {
      let result = "";
      gift.classStatus = "";
      /*let endDate = this.$dayjs(userSubscription.endDate).format("YYYY-MM-DD");*/
      let today = this.$dayjs().format("YYYY-MM-DD");
      today = this.$dayjs(today);
      let startDate = this.$dayjs(gift.startDate);
      // endDate = "2024-06-10";
      let endDate = this.$dayjs(gift.endDate);
      // today = new Date(today);
      if (
        (gift.giftCertificateStatus &&
          gift.giftCertificateStatus.giftCertificateStatusId == 3) ||
        (gift.metaData &&
          gift.metaData.giftadjustmenttype &&
          gift.metaData.giftadjustmenttype.product_id)
      ) {
        result = "UTILIZZATO";
        gift.classStatus = "used";
      } else {
        if (startDate <= today && endDate.diff(today, "day") >= 5) {
          result = "VALIDO";
          gift.classStatus = "valid";
        } else if (startDate <= today && endDate.diff(today, "day") < 5) {
          result = "IN SCADENZA";
          gift.classStatus = "expiring";
        } else if (startDate <= today && endDate < today) {
          result = "SCADUTO";
          gift.classStatus = "expired";
        }
      }
      return result;
    },
    classStatus(gift) {
      let result = "";
      /*let endDate = this.$dayjs(userSubscription.endDate).format("YYYY-MM-DD");*/
      let today = this.$dayjs().format("YYYY-MM-DD");
      today = this.$dayjs(today);
      let startDate = this.$dayjs(gift.startDate);
      // endDate = "2024-06-10";
      let endDate = this.$dayjs(gift.endDate);
      // today = new Date(today);
      if (
        (gift.giftCertificateStatus &&
          gift.giftCertificateStatus.giftCertificateStatusId == 3) ||
        (gift.metaData &&
          gift.metaData.giftadjustmenttype &&
          gift.metaData.giftadjustmenttype.product_id)
      ) {
        result = "used";
      } else {
        if (startDate <= today && endDate.diff(today, "day") >= 5) {
          result = "valid";
        } else if (startDate <= today && endDate.diff(today, "day") < 5) {
          result = "expiring";
        } else if (startDate <= today && endDate > today) {
          result = "expired";
        }
      }
      return result;
    },
    copyCode(code) {
      navigator.clipboard.writeText(code);
      global.EventBus.$emit("success", {
        message: global.EventBus.$t("gift.code.copied")
      });
    },
    async handleAddUserGift(codeId) {
      this.addUserGift(codeId);
      await this.getCart(true);
    },
    async handleAdd(giftCertificate) {
      if (giftCertificate.userGiftCertificates?.length > 0) {
        let userGiftCertificate = giftCertificate.userGiftCertificates[0];
        await this.addGiftCode(userGiftCertificate.userGiftCertificateId);
      } else {
        await this.sendGiftCode(this.giftCertificate.giftCode);
      }
      await this.getCart(true);
      this.$emit("add");
    }
  },
  created() {
    const enabledSocialPlatforms = {
      mail: true
    };
    this.socialPlatforms = Object.keys(enabledSocialPlatforms).filter(
      socialPlatform => enabledSocialPlatforms[socialPlatform] === true
    );
  }
};
</script>