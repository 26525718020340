<template>
  <div v-if="autoAssigned">
    <!-- <h2 v-if="title" class="text-uppercase font-weight-bold default--text my-5">
      {{ title }}
    </h2> -->
    <GiftCard
      v-for="gift in filteredAutoGifts"
      :key="
        gift.userGiftCertificateId
          ? gift.userGiftCertificateId
          : gift.giftCertificateId
      "
      :giftCertificate="gift"
      :mode="mode"
      :autoAssigned="autoAssigned"
      class="mb-4 pb-4"
      @add="$emit('reload')"
    />
  </div>
  <div v-else>
    <!-- <h2 v-if="title" class="text-uppercase font-weight-bold default--text my-5">
      {{ title }}
    </h2> -->
    <GiftCard
      v-for="gift in filteredGifts"
      :key="
        gift.userGiftCertificateId
          ? gift.userGiftCertificateId
          : gift.giftCertificateId
      "
      :giftCertificate="gift"
      :mode="mode"
      :autoAssigned="autoAssigned"
      class="mb-4 pb-4"
      @add="$emit('reload')"
    />
  </div>
</template>

<style scoped lang="scss">
h2 {
  font-size: 20px;
}
.widget {
  min-height: 360px;
  background-size: contain;
}
</style>

<script>
import GiftCard from "./GiftCard.vue";
import cloneDeep from "lodash/cloneDeep";

export default {
  components: { GiftCard },
  name: "GiftList",
  props: {
    autoAssigned: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      required: false
    },
    giftList: {
      type: Array,
      default: () => []
    },
    mode: {
      type: String,
      default: "full"
    }
  },
  data() {
    return {
      giftFilter: []
    };
  },
  computed: {
    filteredGifts() {
      let _this = this;
      let today = this.$dayjs();
      let filteredCertificates = cloneDeep(this.giftList);
      console.log("GF ", this.giftList);

      for (let i = 0; i < filteredCertificates.length; i++) {
        let userG = [];
        let gift = filteredCertificates[i];
        userG = gift.userGiftCertificates.filter(userGift => {
          let startDate = _this.$dayjs(userGift.startDate);
          let endDate = _this.$dayjs(userGift.endDate);

          if (_this.giftFilter.length === 0) {
            return true;
          } else if (_this.giftFilter[0].id === 3) {
            return userGift.giftCertificateStatus.giftCertificateStatusId === 3;
          } else if (_this.giftFilter[0].id === 2) {
            return (
              userGift.giftCertificateStatus.giftCertificateStatusId != 3 &&
              startDate <= today &&
              endDate.diff(today, "day") >= 5
            );
          } else if (_this.giftFilter[0].id === 1) {
            return (
              userGift.giftCertificateStatus.giftCertificateStatusId != 3 &&
              startDate <= today &&
              endDate.diff(today, "day") >= 0 &&
              endDate.diff(today, "day") < 5
            );
          } else if (_this.giftFilter[0].id === 4) {
            return startDate <= today && endDate < today;
          } else {
            return false;
          }
        });
        gift.userGiftCertificates = userG;
      }
      return filteredCertificates;

      // return this.giftList.map(gift => {
      //   if (
      //     gift.redemption == "manualAssignment" &&
      //     gift.userGiftCertificates
      //   ) {
      //     filteredCertificates = gift.userGiftCertificates.filter(userGift => {
      //       let startDate = _this.$dayjs(userGift.startDate);
      //       let endDate = _this.$dayjs(userGift.endDate);

      //       if (_this.giftFilter.length === 0) {
      //         return true;
      //       }

      //       if (_this.giftFilter[0].id === 3) {
      //         return (
      //           userGift.giftCertificateStatus.giftCertificateStatusId === 3
      //         );
      //       } else if (_this.giftFilter[0].id === 2) {
      //         return startDate <= today && endDate.diff(today, "day") >= 5;
      //       } else if (_this.giftFilter[0].id === 1) {
      //         return (
      //           startDate <= today &&
      //           endDate.diff(today, "day") >= 0 &&
      //           endDate.diff(today, "day") < 5
      //         );
      //       } else if (_this.giftFilter[0].id === 4) {
      //         return startDate <= today && endDate < today;
      //       }

      //       return false;
      //     });
      //   }
      //   console.log("filteredCertifcates :", filteredCertificates);
      //   return {
      //     ...gift,
      //     userGiftCertificates: filteredCertificates
      //   };
      // });
    },
    filteredAutoGifts() {
      let _this = this;
      let today = this.$dayjs();

      return this.giftList.filter(gift => {
        let startDate = _this.$dayjs(gift.startDate);
        let endDate = _this.$dayjs(gift.endDate);
        if (_this.giftFilter.length === 0) {
          return true;
        }

        if (_this.giftFilter[0].id === 3) {
          return (
            gift.metaData &&
            gift.metaData.giftadjustmenttype &&
            gift.metaData.giftadjustmenttype.product_id
          );
        } else if (_this.giftFilter[0].id === 2) {
          return (
            !(
              gift.metaData &&
              gift.metaData.giftadjustmenttype &&
              gift.metaData.giftadjustmenttype.product_id
            ) &&
            startDate <= today &&
            endDate.diff(today, "day") >= 5
          );
        } else if (_this.giftFilter[0].id === 1) {
          return (
            !(
              gift.metaData &&
              gift.metaData.giftadjustmenttype &&
              gift.metaData.giftadjustmenttype.product_id
            ) &&
            startDate <= today &&
            endDate.diff(today, "day") >= 0 &&
            endDate.diff(today, "day") < 5
          );
        } else if (_this.giftFilter[0].id === 4) {
          return startDate <= today && endDate < today;
        }
      });
    }
  },
  methods: {
    onFilterGifts(giftFilter) {
      this.giftFilter = giftFilter;
    }
  },
  created() {
    global.EventBus.$on("filterGifts", this.onFilterGifts);
  },
  beforeDestroy() {
    global.EventBus.$off("filterGifts", this.onFilterGifts);
  }
};
</script>
