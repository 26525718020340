<template>
  <v-card
    class="gift-card  default--text"
    :class="mode"
    :flat="mode == 'checkout'"
  >
    <v-row no-gutters>
      <v-col
        class="justify-center align-center"
        :cols="mode == 'full' ? 12 : 2"
        :sm="mode == 'full' ? 2 : 1"
      >
        <v-img
          cover
          v-if="src"
          :src="src"
          alt="Invita un amico"
          class="rounded-sm h-100 invite-friend-img"
        />
        <div
          v-else
          class="certificate-box is-card d-flex flex-column justify-space-around align-center"
          :class="cssClass"
          @click.prevent.stop="handleClick"
          outlined
        >
          <span class="icon-circle">
            <v-icon>{{ icon }}</v-icon>
          </span>
        </div>
      </v-col>

      <v-col
        :cols="mode == 'full' ? 12 : 10"
        :sm="mode == 'full' ? 10 : 11"
        class="d-flex flex-column body-column"
      >
        <div class="text-body-1 font-weight-bold">
          {{ title }}
        </div>
        <div class="description" v-html="description"></div>

        <v-card-actions class="pa-0 mt-2 space-between align-center">
          <v-btn
            outlined
            :large="mode == 'full'"
            :small="mode == 'checkout'"
            depressed
            color="primary"
            to="/invite-friend"
          >
            {{ $t("invite.tellFriend.cta") }}
          </v-btn>
        </v-card-actions>

        <v-spacer />
      </v-col>
    </v-row>
  </v-card>
</template>
<style scoped lang="scss">
.gift-card {
  .invite-friend-img {
    height: 100%;
  }
  &.checkout {
    .icon-circle {
      padding: 8px;
      .v-icon {
        font-size: 18px;
      }
    }
    border-bottom: 1px solid var(--v-grey-lighten4);
    border-radius: 0px;
    .body-column {
      padding-left: 8px;
    }
  }
  &.full {
    border-radius: 4px;
    .body-column {
      padding: 20px !important;
    }
  }
  .v-image {
    @media #{map-get($display-breakpoints, 'xs-only')} {
      border-bottom-left-radius: 0 !important;
      border-bottom-right-radius: 0 !important;
    }
    @media #{map-get($display-breakpoints, 'sm-and-up')} {
      border-top-right-radius: 0 !important;
      border-bottom-right-radius: 0 !important;
    }
  }
  .expiration {
    width: fit-content;
    padding: 2px;
  }
  .description {
    ::v-deep ul {
      padding-left: 24px !important;
      li {
        list-style: disc !important;
      }
    }
  }
}
</style>
<script>
export default {
  name: "InviteCard",
  props: {
    mode: {
      type: String,
      default: "full"
    },
    title: {
      type: String,
      required: true
    },
    description: {
      type: String,
      required: true
    },
    src: {
      type: String,
      required: true
    }
  }
  // computed: {
  //   src() {
  //     return this.$vuetify.breakpoint.xs
  //       ? "/img_layout/invite/invite_sm.png"
  //       : "/img_layout/invite/invite_lg.png";
  //   }
  // }
};
</script>
